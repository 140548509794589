import React from "react";
import UsersTableRow from "./UsersTableRow";
import { baseUrl } from "../config";
import { ReactComponent as IconUp } from "../assets/img/Icons/Arrow_up.svg";
import { ReactComponent as IconDown } from "../assets/img/Icons/Arrow_down.svg";

function UsersTable({ searchValue }) {
    const [items, setItems] = React.useState([]);
    const [initialData, setInitialData] = React.useState([]);
    const [sortBy, setSortBy] = React.useState(null);

    React.useEffect(() => {
        fetch(baseUrl + `/user-service/get-user-list`)
            .then((res) => res.json())
            .then((arr) => {
                setItems(arr.data);
                setInitialData(arr.data);
            });
    }, []);

    React.useMemo(() => {
        let sortableItems = [...initialData];
        if (sortBy !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortBy.field].toLowerCase() < b[sortBy.field].toLowerCase()) {
                    return sortBy.direction === "asc" ? -1 : 1;
                }
                if (a[sortBy.field].toLowerCase() > b[sortBy.field].toLowerCase()) {
                    return sortBy.direction === "asc" ? 1 : -1;
                }
                return 0;
            });
        }
        setItems(sortableItems);
    }, [initialData, sortBy]);

    const handleChange = (field) => {
        let direction = "";

        if (sortBy === null) {
            direction = "asc";
        } else if (sortBy?.field === field) {
            if (sortBy?.direction === "asc") {
                direction = "desc";
            } else if (sortBy?.direction === "desc") {
                setSortBy(null);
                return null;
            }
        } else {
            direction = "asc";
        }

        setSortBy({ field: field, direction: direction });
    };

    const printIcon = (field) => {
        if (sortBy?.field === field) {
            if (sortBy.direction === "asc") {
                return <IconDown />;
            } else return <IconUp />;
        }
        return null;
    };

    return (
        <>
            <div className="users-table">
                <div className="users-table-head users-table-row">
                    <div className="users-table-head__item cursor" onClick={() => handleChange("name")}>
                        <p>Name</p>
                        <div className="sort-icon">{printIcon("name")}</div>
                    </div>

                    <div>
                        <p>Phone number</p>
                    </div>
                    <div>
                        <p>Link</p>
                    </div>

                    <div className="users-table-head__item cursor" onClick={() => handleChange("status")}>
                        <p>Status</p>
                        <div className="sort-icon">{printIcon("status")}</div>
                    </div>

                    <div className="users-table-head__item cursor" onClick={() => handleChange("role")}>
                        <p>Role</p>
                        <div className="sort-icon">{printIcon("role")}</div>
                    </div>
                </div>

                {items
                    .filter((obj) => {
                        if ((obj.name + " " + obj.surname).toLowerCase().includes(searchValue.toLowerCase())) {
                            return true;
                        }
                        return false;
                    })
                    .map((obj) => (
                        <UsersTableRow {...obj} key={obj.id} />
                    ))}
            </div>
            {items.length === 0 && (
                <div className="table_empty">
                    <h4 className="title">There’s no one here - yet</h4>
                    <p className="text">Time to add some people</p>
                </div>
            )}
        </>
    );
}

export default UsersTable;

