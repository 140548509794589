import React from "react";
import { useNavigate } from "react-router-dom";

function UsersTableRow({ name, surname, role, phone, link, status, id }) {
    const navigate = useNavigate();

    const handleCardClick = () => {
        navigate(`/users/${id}`);
    };

    const handleLinkClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div className="users-table-row users-table__link" onClick={handleCardClick}>
            <div className="users-table-row__name">{name + " " + surname}</div>

            <div className="users-table-row__phone">{phone}</div>
            <div className="users-table-row__link-wrapper">
                <a
                    className="users-table-row__link link"
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    onClick={handleLinkClick}
                >
                    {link}
                </a>
            </div>

            <div className="users-table-row__status ">
                <div className={status === "Active" ? "status-bg active" : "status-bg blocked"}>{status}</div>
            </div>
            <div className="users-table-row__role">{role}</div>
        </div>
    );
}

export default UsersTableRow;

