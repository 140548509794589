import React from "react";
import { Popover } from "react-tiny-popover";
import { ReactComponent as Chevron } from "../assets/img/Icons/Plus.svg";
import Button from "./Button";
import { Link } from "react-router-dom";

function Dropdown() {
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

    return (
        <div className={`popover `}>
            <div>
                <Popover
                    isOpen={isPopoverOpen}
                    onClickOutside={() => setIsPopoverOpen(false)}
                    positions={["bottom"]} // preferred positions by priority
                    containerStyle={{ top: "-42px" }}
                    align="end"
                    padding={4}
                    content={
                        <div className={`dropdown`}>
                            <Link to="/equipment_glossary/add-item">
                                <Button btnClassName="dropdown__item" btnLabel={"Item"} />
                            </Link>
                            <Button btnClassName="dropdown__item" btnLabel={"Group"} disabled={true} />
                            <Button btnClassName="dropdown__item" btnLabel={"Type"} disabled={true} />
                        </div>
                    }
                >
                    <div className={``}>
                        <Button
                            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                            btnLabel="New"
                            btnClassName={`popover__btn btn_default ${
                                isPopoverOpen ? "popover__btn_open" : "popover__btn_close"
                            }`}
                        >
                            {<Chevron />}
                        </Button>
                    </div>
                </Popover>
            </div>
        </div>
    );
}
export default Dropdown;

