import React from "react";
import axios from "axios";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import ButtonText from "../components/ButtonText";
import Button from "../components/Button";
import { baseUrl } from "../config";
import Select from "react-select";
import Modal from "../components/Modal";

function EquipmentCard() {
    const {
        register,
        formState: { errors, isDirty, isValid },
        handleSubmit,
        reset,
        control,
        getValues,
    } = useForm({
        defaultValues: {
            rating: "1",
            description: "",
        },
        mode: "onChange",
        reValidateMode: "onBlur",
    });

    const navigate = useNavigate();
    const [groups, setGroups] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [modalActive, setModalActive] = React.useState(false);

    const { id } = useParams();

    console.log(getValues());

    React.useEffect(() => {
        fetch(baseUrl + `/equipment-group-service/get-equipment-group-list`)
            .then((res) => res.json())
            .then((arr) => {
                setGroups(arr.data);
            });
    }, []);

    const deleteItem = () => {
        fetch(baseUrl + `/equipment-service/delete-equipment?id=${id}`, { method: "DELETE" })
            .then((res) => res.json())
            .then((data) => {
                setModalActive(false);
                navigate("/equipment_glossary");
            })
            .catch((error) => console.error(error));
    };

    const updateEquipmentItem = (data) => {
        fetch(baseUrl + `/equipment-service/update-equipment`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((data) => {
                navigate("/equipment_glossary");
            })
            .catch((error) => console.error(error));
    };

    React.useEffect(() => {
        async function fetchUser() {
            try {
                const { data } = await axios.get(baseUrl + `/equipment-service/get-equipment?id=${id}`);
                console.log(data.data);
                const a = data.data;
                a.rating = String(a.rating);
                reset(a);

                setIsLoading(false);
            } catch (error) {
                alert("Ошибка при получении информации по оборудованию!");
                navigate("/equipment_glossary");
            }
        }

        fetchUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <>Загрузка...</>;
    }

    return (
        <div className="add-user__wrapper">
            <Link to="/equipment_glossary">
                <div className="add-user__icon-back">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                        <path
                            d="M20 26L10 16L20 6"
                            stroke="#0A0A0A"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
            </Link>

            <div className="add-user__content">
                <h3 className="add-user__title">Item card</h3>

                <div className="add-user__info">
                    <form className="add-user__form form" onSubmit={handleSubmit(updateEquipmentItem)}>
                        <div className="form_active">
                            <div className="form__block">
                                <label htmlFor="innerId" className="label">
                                    Identifier
                                </label>
                                <input
                                    {...register("innerId", {
                                        required: "This is required",
                                        minLength: {
                                            value: 2,
                                            message: "Inventory number should have from 2 to 20 characters",
                                        },
                                        maxLength: {
                                            value: 20,
                                            message: "Inventory number should have from 2 to 20 characters",
                                        },
                                    })}
                                    type="text"
                                    name="innerId"
                                    id="innerId"
                                    className="input"
                                    placeholder="Identifier"
                                />
                                <div className="error">
                                    {errors?.innerId && <p className="error">{errors?.innerId?.message || "Error!"}</p>}
                                </div>
                            </div>
                            <div className="form__block">
                                <label htmlFor="name" className="label">
                                    Name
                                </label>
                                <input
                                    {...register("name", {
                                        required: "This is required",
                                        minLength: {
                                            value: 2,
                                            message: "Equipment name should have from 2 to 50 characters",
                                        },
                                        maxLength: {
                                            value: 50,
                                            message: "Equipment name should have from 2 to 50 characters",
                                        },
                                    })}
                                    type="text"
                                    name="name"
                                    id="name"
                                    className="input"
                                    placeholder="Name"
                                />
                                <div className="error">
                                    {errors?.name && <p className="error">{errors?.name?.message || "Error!"}</p>}
                                </div>
                            </div>
                            <div className="form__block">
                                <p className="label">Group</p>
                                <div>
                                    <Controller
                                        render={(props) => {
                                            return (
                                                <Select
                                                    className="select"
                                                    styles={{
                                                        option: (styles, { isFocused, isSelected }) => {
                                                            return {
                                                                ...styles,
                                                                backgroundColor: isFocused ? "#f5f5f5" : "#ffffff",
                                                                color: isSelected ? "#694df9" : "#0a0a0a",
                                                            };
                                                        },
                                                    }}
                                                    options={groups
                                                        .sort((a, b) => a.name.localeCompare(b.name))
                                                        .map((group) => ({
                                                            value: group.name,
                                                            label: group.name,
                                                        }))}
                                                    onChange={({ value }) => {
                                                        props.field.onChange(value);
                                                    }}
                                                    placeholder="Select"
                                                    value={
                                                        props.field.value
                                                            ? { value: props.field.value, label: props.field.value }
                                                            : null
                                                    }
                                                />
                                            );
                                        }}
                                        name="group"
                                        control={control}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                </div>
                                <div className="error">
                                    {errors.group && <p className="error">{errors?.group?.message || "Error!"}</p>}
                                </div>
                            </div>
                            <div className="form__block">
                                <label htmlFor="year" className="label">
                                    Year
                                </label>
                                <input
                                    {...register("year", {
                                        required: "This is required",
                                        min: {
                                            value: 2010,
                                            message: "Equipment year should be in the range [2010-2049]",
                                        },
                                        max: {
                                            value: 2024,
                                            message: "Equipment year should be in the range [2010-2049]",
                                        },
                                        minLength: {
                                            value: 4,
                                            message: "Equipment year should be in the range [2010-2049]",
                                        },
                                        maxLength: {
                                            value: 4,
                                            message: "Equipment year should be in the range [2010-2049]",
                                        },
                                    })}
                                    type="number"
                                    name="year"
                                    id="year"
                                    className="input"
                                    placeholder="Year"
                                />
                                <div className="error">
                                    {errors?.year && <p className="error">{errors?.year?.message || "Error!"}</p>}
                                </div>
                            </div>

                            <div className="form__block">
                                <p className="label">Rating</p>
                                <div className="radio">
                                    <div className="radio_item">
                                        <label htmlFor="rating1" className="radio__label">
                                            <input
                                                {...register("rating")}
                                                name="rating"
                                                type="radio"
                                                id="rating1"
                                                className="radio__input"
                                                value="1"
                                            />{" "}
                                            <span>1</span>
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="radio_item">
                                        <label htmlFor="rating2" className="radio__label">
                                            <input
                                                {...register("rating")}
                                                name="rating"
                                                type="radio"
                                                id="rating2"
                                                className="radio__input"
                                                value="2"
                                            />
                                            <span>2</span>
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="radio_item">
                                        <label htmlFor="rating3" className="radio__label">
                                            <input
                                                {...register("rating")}
                                                name="rating"
                                                type="radio"
                                                id="rating3"
                                                className="radio__input"
                                                value="3"
                                            />
                                            <span>3</span>
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="error">
                                    {errors?.rating && <p className="error">{errors?.rating?.message || "Error!"}</p>}
                                </div>
                            </div>
                            <div className="form__block">
                                <label htmlFor="description" className="label">
                                    Description <span className="font-size-small">-</span>{" "}
                                    <span className="style-italic">Optional</span>
                                </label>
                                <div>
                                    <Controller
                                        name="description"
                                        control={control}
                                        render={({ field }) => {
                                            return (
                                                <textarea
                                                    onChange={field.onChange}
                                                    value={field.value}
                                                    className="textarea"
                                                    id="description"
                                                    maxLength={1000}
                                                />
                                            );
                                        }}
                                    />
                                </div>{" "}
                            </div>
                            <div className="error">
                                {errors.description && (
                                    <p className="error">{errors?.description?.message || "Error!"}</p>
                                )}
                            </div>

                            <div className="btn-container">
                                <ButtonText onClick={() => setModalActive(true)} btnLabel="Delete item" type="button" />

                                <Button
                                    btnLabel="Done"
                                    btnClassName="btn_default btn-medium"
                                    disabled={!isDirty || !isValid}
                                    type="submit"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <Modal active={modalActive} setActive={setModalActive}>
                <h3 className="modal__title">Delete item</h3>
                <p className="modal__text">This action permanently deletes the item</p>
                <div className="btns__container">
                    <Button
                        btnLabel="Cancel"
                        btnClassName="btn_outline btn-medium"
                        onClick={() => setModalActive(false)}
                        type="button"
                    />

                    <Button btnLabel="Delete item" btnClassName="btn_default btn-medium" onClick={deleteItem} />
                </div>
            </Modal>
        </div>
    );
}

export default EquipmentCard;

