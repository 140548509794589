import React, { useEffect } from "react";
import Search from "../components/Search";
import EquipmentTable from "../components/EquipmentTable";
import TabsMenu from "../components/TabsMenu.tsx";
import Dropdown from "../components/Dropdown";
import { baseUrl } from "../config";

function EquipmentGlossary() {
    const [searchValue, setSearchValue] = React.useState("");
    const [tabs, setTabs] = React.useState([]);
    const [activeTab, setActiveTab] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);

    useEffect(() => {
        fetch(baseUrl + `/equipment-type-service/get-equipment-type-list`)
            .then((res) => res.json())
            .then((arr) => {
                setTabs(arr.data.sort((a, b) => a.name.localeCompare(b.name)));
                setActiveTab(arr.data[0]);
                console.log(arr.data[0]);
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        setSearchValue("");
    }, [activeTab]);

    if (isLoading) {
        return <>Загрузка...</>;
    }

    return (
        <>
            {activeTab ? (
                <TabsMenu tabs={tabs} activeTabId={activeTab} setActiveTabId={setActiveTab} />
            ) : (
                <div className="tabs tabs_empty"></div>
            )}

            <div className="search-wrapper">
                <Search searchValue={searchValue} setSearchValue={setSearchValue} placeholder={"Search by item..."} />
                <div className="equipment__btn-wrapper">
                    <Dropdown />
                </div>
            </div>
            <EquipmentTable searchValue={searchValue} activeTab={activeTab} />
        </>
    );
}

export default EquipmentGlossary;

